import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { MatchStatus, Package } from '../types/enrichmentTypes';

export const enrichmentApi = createApi({
	reducerPath: 'enrichmentApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getEnrichmentPackages: builder.query<Package[], void>({
			query: () => '/enrichment/packages/',
			keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
		}),
		getEnrichmentFieldNames: builder.query<Record<string, string>, void>({
			query: () => '/enrichment/field_names/',
		}),
		getEnrichmentMatchStatuses: builder.query<
			MatchStatus[],
			{ taskId: number; reportId: number; recordId: number }
		>({
			query: ({ taskId, reportId, recordId }) => ({
				url: `/enrichment/${taskId}/records/${recordId}/match_statuses/`,
				params: reportId ? { report_id: reportId } : undefined,
			}),
		}),
	}),
});

export const { useGetEnrichmentPackagesQuery, useLazyGetEnrichmentMatchStatusesQuery } = enrichmentApi;
