import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridOverlay, type GridOverlayProps, useGridApiContext } from '@mui/x-data-grid-pro';

function GridNoRowsOverlay(props: GridOverlayProps) {
	const apiRef = useGridApiContext();
	const noRowsLabel = apiRef.current.getLocaleText('noRowsLabel') ?? '';
	let noRowsSecondaryLabel: string | undefined;
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		noRowsSecondaryLabel = apiRef.current.getLocaleText('noRowsSecondaryLabel');
	} catch {
		// empty
	}

	return (
		<GridOverlay {...props}>
			<Box>
				<Typography
					variant="body1"
					fontWeight="medium"
					align="center"
					sx={noRowsSecondaryLabel ? { mb: 1 } : undefined}
				>
					{noRowsLabel}
				</Typography>
				{noRowsSecondaryLabel && (
					<Typography variant="body2" color="textSecondary">
						{noRowsSecondaryLabel}
					</Typography>
				)}
			</Box>
		</GridOverlay>
	);
}

export default GridNoRowsOverlay;
