import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
	closeSnackbar,
	type SnackbarKey,
	type SnackbarOrigin,
	SnackbarProvider,
	type SnackbarProviderProps,
} from 'notistack';

import CustomSnackbar from './CustomSnackbar';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' };

const SnackbarComponents = {
	error: CustomSnackbar,
	success: CustomSnackbar,
	warning: CustomSnackbar,
	info: CustomSnackbar,
};

const defaultAction = (key: SnackbarKey) => (
	<IconButton size="small" color="inherit" onClick={() => closeSnackbar(key)}>
		<CloseIcon fontSize="small" />
	</IconButton>
);

function RingLeadSnackbarProvider({ ...props }: SnackbarProviderProps) {
	return (
		<SnackbarProvider
			autoHideDuration={6000}
			anchorOrigin={anchorOrigin}
			Components={SnackbarComponents}
			maxSnack={5}
			preventDuplicate
			hideIconVariant
			action={defaultAction}
			{...props}
		/>
	);
}

export default RingLeadSnackbarProvider;
