import Typography from '@mui/material/Typography';
import { GridOverlay, type GridOverlayProps, useGridApiContext } from '@mui/x-data-grid-pro';

function GridNoResultsOverlay(props: GridOverlayProps) {
	const apiRef = useGridApiContext();
	const noResultsOverlayLabel = apiRef.current.getLocaleText('noResultsOverlayLabel') ?? '';

	return (
		<GridOverlay {...props}>
			<Typography variant="body1" fontWeight="medium" align="center">
				{noResultsOverlayLabel}
			</Typography>
		</GridOverlay>
	);
}

export default GridNoResultsOverlay;
