import concat from 'lodash-es/concat';
import find from 'lodash-es/find';
import map from 'lodash-es/map';
import { LOCATION_CHANGE } from 'redux-first-history';

import { NormalizerRuleConstants } from '../Constants';

const initialState = {
	normalizerOptions: [],
	fetchingOptions: true,
	normalizerRules: [],
	fetchingRules: true,
	normalizerFields: [],
	fetchingNormalizerFields: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case NormalizerRuleConstants.NORMALIZER_RULE_OPTIONS_SUCCESS:
			return { ...state, normalizerOptions: action.data, fetchingOptions: false };

		case NormalizerRuleConstants.NORMALIZER_RULE_OPTIONS_FAIL:
			return { ...state, normalizerOptions: [], fetchingOptions: false };

		case NormalizerRuleConstants.NORMALIZER_RULE_LIST_SUCCESS:
			return { ...state, normalizerRules: action.data, fetchingRules: false };

		case NormalizerRuleConstants.NORMALIZER_RULE_LIST_FAIL:
			return { ...state, normalizerRules: [], fetchingRules: false };

		case NormalizerRuleConstants.NORMALIZER_RULE_SYNC_SUCCESS:
			return { ...state, normalizerRules: action.data };

		case NormalizerRuleConstants.NORMALIZER_RULE_CREATE_SUCCESS:
			return {
				...state,
				normalizerRules: concat([], state.normalizerRules, action.data),
			};

		case NormalizerRuleConstants.NORMALIZER_RULE_UPDATE_SUCCESS:
			return {
				...state,
				normalizerRules: map(state.normalizerRules, o => {
					const updatedRule = find(action.data, { id: o.id });

					return updatedRule ? { ...o, ...updatedRule } : o;
				}),
			};

		case NormalizerRuleConstants.NORMALIZER_RULE_CREATE_FAIL:
		case NormalizerRuleConstants.NORMALIZER_RULE_UPDATE_FAIL:
			return state;

		case NormalizerRuleConstants.NORMALIZER_FIELDS_GET:
			return { ...state, fetchingNormalizerFields: true };

		case NormalizerRuleConstants.NORMALIZER_FIELDS_GET_SUCCESS:
			return { ...state, normalizerFields: action.data, fetchingNormalizerFields: false };

		case NormalizerRuleConstants.NORMALIZER_FIELDS_GET_FAIL:
			return { ...state, normalizerFields: [], fetchingNormalizerFields: false };

		default:
			return state;
	}
};
