import type { User } from 'app/auth/types/user';

import type { BasePermissionObject } from '../types/permissions';

const hasPermissionAccess = (user: User, permission: string, object: BasePermissionObject | null = null) => {
	if (!user.organization || !user.organization.is_beta) {
		return true;
	}

	if (!user.permissions?.[permission]) {
		return false;
	}

	if (user.permissions[permission].owners_only && object) {
		if (object.owner_user && object.owner_user.id !== user.id) {
			return false;
		}

		if (object.owner_group && !object.owner_group.users.find(u => u.id === user.id)) {
			return false;
		}
	}

	return true;
};

export const hasFeatureAccess = (
	user: User,
	features: string[] | null,
	permission: string | null,
	object: BasePermissionObject | null = null
) => {
	if (!user || (!user.is_staff && features?.some(feature => !user.available_products.includes(feature)))) {
		return false;
	}

	return !permission || hasPermissionAccess(user, permission, object);
};

export const getOwnerLabel = (object: BasePermissionObject) => {
	if (object.owner_user) {
		return object.owner_user.email;
	}
	if (object.owner_group) {
		return object.owner_group.name;
	}
	return '';
};
